<template>
  <div>
    <aside
      v-if="isMobile"
      :class="{
        'mobile-position': isMobile,
      }"
      class="flex h-full"
    >
      <div
        :class="{
          'absolute-sidebar': isMobile,
        }"
        class="flex flex-1"
      >
        <transition name="slide-fade">
          <primary-sidebar
            v-if="showSecondarySidebar"
            class="z-20"
            :logo-source="globalConfig.logoThumbnail"
            :installation-name="globalConfig.installationName"
            :is-a-custom-branded-instance="isACustomBrandedInstance"
            :account-id="accountId"
            :menu-items="primaryMenuItems"
            :chat-menu-item="chatMenuItem"
            :active-menu-item="activePrimaryMenu.key"
            @toggle-accounts="toggleAccountModal"
            @key-shortcut-modal="toggleKeyShortcutModal"
            @open-notification-panel="openNotificationPanel"
          />
        </transition>
        <transition name="slide-fade">
          <secondary-sidebar
            v-if="showSecondarySidebar"
            :class="['z-10', sidebarClassName]"
            :account-id="accountId"
            :inboxes="inboxes"
            :labels="labels"
            :teams="teams"
            :custom-views="customViews"
            :menu-config="activeSecondaryMenu"
            :current-role="currentRole"
            :is-on-chatwoot-cloud="isOnChatwootCloud"
            @add-label="showAddLabelPopup"
            @toggle-accounts="toggleAccountModal"
          />
        </transition>
      </div>
    </aside>

    <aside
      v-else
      :class="{
        'mobile-position': isMobile,
      }"
      class="flex h-full"
    >
      <primary-sidebar
        :logo-source="globalConfig.logoThumbnail"
        :installation-name="globalConfig.installationName"
        :is-a-custom-branded-instance="isACustomBrandedInstance"
        :account-id="accountId"
        :menu-items="primaryMenuItems"
        :chat-menu-item="chatMenuItem"
        :active-menu-item="activePrimaryMenu.key"
        @toggle-accounts="toggleAccountModal"
        @key-shortcut-modal="toggleKeyShortcutModal"
        @open-notification-panel="openNotificationPanel"
      />
      <secondary-sidebar
        v-if="showSecondarySidebar"
        :class="[computedAbsoluteSidebar, sidebarClassName]"
        :account-id="accountId"
        :inboxes="inboxes"
        :labels="labels"
        :teams="teams"
        :custom-views="customViews"
        :menu-config="activeSecondaryMenu"
        :current-role="currentRole"
        :is-on-chatwoot-cloud="isOnChatwootCloud"
        @add-label="showAddLabelPopup"
        @toggle-accounts="toggleAccountModal"
      />
    </aside>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../mixins/isAdmin';
import { getSidebarItems } from './config/default-sidebar';
import alertMixin from 'shared/mixins/alertMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import PrimarySidebar from './sidebarComponents/Primary.vue';
import SecondarySidebar from './sidebarComponents/Secondary.vue';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import router from '../../routes';

export default {
  components: {
    PrimarySidebar,
    SecondarySidebar,
  },
  mixins: [
    adminMixin,
    alertMixin,
    keyboardEventListenerMixins,
    uiSettingsMixin,
  ],
  props: {
    showSecondarySidebar: {
      type: Boolean,
      default: true,
    },
    sidebarClassName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOptionsMenu: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentRole: 'getCurrentRole',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      inboxes: 'inboxes/getInboxes',
      isACustomBrandedInstance: 'globalConfig/isACustomBrandedInstance',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      isOnChatwootCloud: 'globalConfig/isOnChatwootCloud',
      labels: 'labels/getLabelsOnSidebar',
      teams: 'teams/getMyTeams',
    }),
    computedAbsoluteSidebar() {
      return this.isMobile ? 'absolute-sidebar' : '';
    },
    activeCustomView() {
      if (this.activePrimaryMenu.key === 'contacts') {
        return 'contact';
      }
      if (this.activePrimaryMenu.key === 'conversations') {
        return 'conversation';
      }
      return '';
    },
    customViews() {
      return this.$store.getters['customViews/getCustomViewsByFilterType'](
        this.activeCustomView
      );
    },
    isConversationOrContactActive() {
      return (
        this.activePrimaryMenu.key === 'contacts' ||
        this.activePrimaryMenu.key === 'conversations'
      );
    },
    sideMenuConfig() {
      return getSidebarItems(this.accountId);
    },
    chatMenuItem() {
      return this.sideMenuConfig.primaryMenu[1];
    },
    primaryMenuItems() {
      const menuItems = this.sideMenuConfig.primaryMenu;
      return menuItems.filter(menuItem => {
        if (
          menuItem.key === 'contacts' &&
          this.currentRole === 'agent' &&
          this.hideContactsForAgents
        ) {
          return false;
        }
        const isAvailableForTheUser = menuItem.roles.includes(this.currentRole);

        if (!isAvailableForTheUser) {
          return false;
        }
        if (
          menuItem.alwaysVisibleOnChatwootInstances &&
          !this.isACustomBrandedInstance
        ) {
          return true;
        }
        if (menuItem.featureFlag) {
          return this.isFeatureEnabledonAccount(
            this.accountId,
            menuItem.featureFlag
          );
        }
        return true;
      });
    },
    activeSecondaryMenu() {
      const { secondaryMenu } = this.sideMenuConfig;
      const { name: currentRoute } = this.$route;

      const activeSecondaryMenu =
        secondaryMenu.find(menuItem =>
          menuItem.routes.includes(currentRoute)
        ) || {};
      return activeSecondaryMenu;
    },
    activePrimaryMenu() {
      const activePrimaryMenu =
        this.primaryMenuItems.find(
          menuItem => menuItem.key === this.activeSecondaryMenu.parentNav
        ) || {};
      return activePrimaryMenu;
    },
    hideContactsForAgents() {
      return (
        this.isFeatureEnabledonAccount(
          this.accountId,
          'hide_contacts_for_agent'
        ) && this.currentRole !== 'administrator'
      );
    },
  },

  watch: {
    activeCustomView() {
      this.fetchCustomViews();
    },
  },
  mounted() {
    this.$store.dispatch('labels/get');
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('notifications/unReadCount');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('attributes/get');
    this.fetchCustomViews();
  },

  methods: {
    fetchCustomViews() {
      if (this.isConversationOrContactActive) {
        this.$store.dispatch('customViews/get', this.activeCustomView);
      }
    },
    toggleKeyShortcutModal() {
      this.$emit('open-key-shortcut-modal');
    },
    closeKeyShortcutModal() {
      this.$emit('close-key-shortcut-modal');
    },
    getKeyboardEvents() {
      return {
        '$mod+Slash': this.toggleKeyShortcutModal,
        '$mod+Escape': this.closeKeyShortcutModal,
        'Alt+KeyC': {
          action: () => this.navigateToRoute('home'),
        },
        'Alt+KeyV': {
          action: () => this.navigateToRoute('contacts_dashboard'),
        },
        'Alt+KeyR': {
          action: () => this.navigateToRoute('account_overview_reports'),
        },
        'Alt+KeyS': {
          action: () => this.navigateToRoute('agent_list'),
        },
      };
    },
    navigateToRoute(routeName) {
      if (!this.isCurrentRouteSameAsNavigation(routeName)) {
        router.push({ name: routeName });
      }
    },
    isCurrentRouteSameAsNavigation(routeName) {
      return this.$route.name === routeName;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    toggleAccountModal() {
      this.$emit('toggle-account-modal');
    },
    showAddLabelPopup() {
      this.$emit('show-add-label-popup');
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
  },
};
</script>

<style scoped>
/* Add these styles */
.absolute-sidebar {
  @apply absolute top-0 left-full h-full z-20 lg:static lg:top-auto lg:left-auto lg:h-auto lg:z-0;
}

.mobile-position {
  @apply relative lg:static;
}

/* Add these styles for transitions */
.slide-fade-enter-active {
  transition: transform 0.5s ease; /* Faster opacity transition when activating */
}
.slide-fade-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease; /* Slower opacity transition when deactivating */
}
.slide-fade-enter,
.slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
